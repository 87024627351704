// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Link, graphql } from 'gatsby';

// Internal Dependencies
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Local Variables
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        author: PropTypes.string,
        date: PropTypes.string,
        title: PropTypes.string,
      }),
      html: PropTypes.string,
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.slug,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }).isRequired,
    }),
    previous: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.slug,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }).isRequired,
    }),
  }),
};

// Component Definition
// eslint-disable-next-line react/prefer-stateless-function
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout variant="blog">
        <Meta title="Blog" />

        <section id="blog-post">
          <header>
            <h1>{post.frontmatter.title}</h1>
            <h4>
              by {post.frontmatter.author}
            </h4>
            <p>
              {post.frontmatter.date}
            </p>
          </header>
          <div
            className="blog-post-body"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <hr />

          <footer>
            <ul>
              <li>
                {previous
                  && (
                  <Link
                    className="blog-post-link"
                    rel="prev"
                    to={`/blog${previous.fields.slug}`}
                  >
                    ←&nbsp;&nbsp;{previous.frontmatter.title}
                  </Link>
                  )}
              </li>

              <li>
                {next
                  && (
                  <Link
                    className="blog-post-link"
                    rel="next"
                    to={`/blog${next.fields.slug}`}
                  >
                    {next.frontmatter.title}&nbsp;&nbsp;→
                  </Link>
                  )}
              </li>
            </ul>
          </footer>
        </section>
      </Layout>
    );
  }
}

BlogPostTemplate.propTypes = propTypes;

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    markdownRemark(
      fields: {
        collection: {
          eq: "blog"
        }
        slug: {
          eq: $slug
        }
      }
    ) {
    id
    excerpt
    frontmatter {
      author
      title
      date(formatString: "MMMM DD, YYYY")
    }
    html
  }
  }
`;
